import { Autocomplete, MenuItem, Select, TextField } from '@mui/material'
import React from 'react'
import styles from './index.module.css'

interface Props {
    label: string;
    value: string;
    onChange: (value: string) => void;
    items: string[];
}

export default function RoundedInputSelect(props: Props) {
  return (
    <Autocomplete
        style={{
          width: '100%',
          minWidth: '100px',
          height: '100%',
          borderRadius: '15px',
          border: '#ccc 2px solid',
          paddingLeft: '10px',
          paddingRight: '10px',
          color: 'black',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        renderInput={(params) => 
          <TextField 
            {...params} 
            label={props.label} 
            variant='standard'
            sx={{
              '& .MuiInputBase-root': {
                border: 'none', // Hide the border
                backgroundColor: 'rgba(255, 255, 255, 0)', // Make background transparent
              },
              '& .MuiInputLabel-root': {
                color: 'rgba(255, 255, 255, 0.01)', // Make label text transparent
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#fff', // Make label text transparent
              },
              '& .MuiInputLabel-root:before': {
                display: 'none', // Hide label underline
              },
              '& .MuiInputLabel-root:hover': {
                color: 'rgba(255, 255, 255, 0.01)', // Make label text transparent
              },
              '& .MuiInputLabel-root:after': {
                display: 'none', // Hide label underline
              },
              '& .MuiInputBase-input': {
                color: '#1e1e1e', // Set input text color
                height: '90%', // Match parent autocomplete height
                marginTop: '-2%'
              },
              '& .MuiInput-underline:before': {
                borderBottomColor: 'rgba(255, 255, 255, 0)', // Hide the line under the input
              },
              '& .MuiInput-underline:hover': {
                borderBottomColor: 'rgba(255, 255, 255, 0)', // Hide the line under the input
              },
              '& .MuiInput-underline:after': {
                borderBottomColor: 'rgba(255, 255, 255, 0.01)', // Hide the line under the input
              },
            }}
          />
        }
        options={props.items}
        value={props.value ?? props.label}
        onChange={(e,value) => props.onChange(value as string)}
        sx={{
          color: "#1e1e1e",
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(228, 219, 233, 0.0)',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(228, 219, 233, 0.0)',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(228, 219, 233, 0.0)',
          },
          '.MuiSvgIcon-root ': {
            fill: "#1e1e1e",
          }
        }}
    />
  )
}
