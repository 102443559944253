import axios, { AxiosError, AxiosResponse } from 'axios';
import { AccountTypes, PropertyRequest, PropertyResponse, SignInRequest, SignInResponse, UserRequest, UserResponse } from './models';

const configuration = {
    //baseURL: 'http://localhost:8000/'
    baseURL: 'https://new-sivic-api.novolar.net/'
}

export default class ApiService {
    private static apiServiceAxios = axios.create(configuration)

    static loginData: { token?: string } = {
        token: undefined,
    };

    static setTokenUsuarioAutenticado(token: string) {
        this.apiServiceAxios.defaults.headers.common['Authorization'] = `Token ${token}`;

        this.loginData.token = token;
    }

    private static enviarRequestAsync = async <T>(
        verb: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
        path: string,
        data: any = undefined,
        auth?: any,
    ): Promise<AxiosResponse<T, any>> => {
        const tokenString = localStorage.getItem('token');

        if (tokenString) {
            this.setTokenUsuarioAutenticado(tokenString);
        }

        const headers = {};

        try {
            switch (verb) {
                case 'GET':
                    return this.apiServiceAxios.get<T>(path, {
                        params: data,
                        headers: headers,
                    });
                case 'PUT':
                case 'PATCH':
                case 'POST':
                    return this.apiServiceAxios({
                        method: verb,
                        data: data,
                        url: path,
                        auth: auth,
                    });
                case 'DELETE':
                    return this.apiServiceAxios({
                        method: verb,
                        data: data,
                        headers: headers,
                        url: path,
                    });

                default:
                    throw new Error('O verbo usado na requisição não é suportado pelo sistema.');
            }
        } catch (error) {
            if (error instanceof AxiosError) {
                console.log(error)
                if (error.response) {
                    // O servidor respondeu com um status code diferente de 200
                    if (error.response.data.erros.length > 0) {
                        throw new Error(error.response.data.erros[0].mensagem);
                    }
                    throw new Error('Ocorreu um erro ao processar sua solicitação.');
                } else if (error.request) {
                    // A requisição foi feita, porém não foi recebida nenhuma resposta do servidor
                    throw new Error('Não foi possível se comunicar com o servidor.');
                } else {
                    // Um erro ocorreu antes do envio da requisição
                    throw error;
                }
            } else {
                throw error;
            }
        }
    };

    public static async login(data: SignInRequest) {
        var response = await this.enviarRequestAsync<SignInResponse>(
            'POST',
            `/api-token-auth/`,
            {
                username: data.email,
                password: data.password,
            },
            {
                Authorization: `Basic ${data.email}:${data.password}`
            },
        );

        this.apiServiceAxios.interceptors.request.use((config:any) => {
            config.headers = {
                Authorization: `Token ${response.data.token}`,
            };
            return config;
        });

        return response.data;
    }

    public static async getUserById(userId: string, page?: number) {
        const response = await this.enviarRequestAsync<UserResponse | UserResponse[]>(
            'GET',
            `/get-user/${userId}/${page? page.toString() : '0'}`
        )
        return response.data;
    }

    public static async getUserBySearchTerm(term: string) {
        const response = await this.enviarRequestAsync<UserResponse | UserResponse[]>(
            'GET',
            `/search-user/${term}/`
        )
        return {data: response.data, term: term};
    }

    public static async createUser(data: UserRequest) {
        const response = await this.enviarRequestAsync<any>(
            'POST',
            `/create-user/`,
            data
        )
        return response.data;
    }

    public static async updateUser(data: UserRequest) {
        const response = await this.enviarRequestAsync<any>(
            'PUT',
            `/create-user/`,
            data
        )
        return response.data;
    }

    public static async getUserByType(type: string) {
        const response = await this.enviarRequestAsync<any>(
            'GET',
            `/get-user-byType/${type}/`
        )
        return response.data;
    }

    public static async getProperty(id: string, page?: number) {
        const response = await this.enviarRequestAsync<any>(
            'GET',
            `/get-property/${id}/${page? page.toString() : '0'}`
        )
        return response.data
    }

    public static async getPropertyBySearchTerm(term: string) {
        const response = await this.enviarRequestAsync<PropertyResponse | PropertyResponse[]>(
            'GET',
            `/search-property/${term}/`
        )
        return {data: response.data, term: term};
    }

    public static async createProperty(data: PropertyRequest) {
        const response = await this.enviarRequestAsync<any>(
            'POST',
            '/create-property/',
            data
        )
        return response.data;
    }

    public static async updateProperty(data: PropertyRequest) {
        const response = await this.enviarRequestAsync<any>(
            'PUT',
            '/create-property/',
            data
        )
        return response.data;
    }

    public static async getImageByProperty(id: string | number) {
        const response = await this.enviarRequestAsync<any>(
            'GET',
            `/get-images-byProperty/${id}/`
        )
        return response.data;
    }

    public static async uploadImage(data: any) {
        const response = await this.enviarRequestAsync<any>(
            'POST',
            `/create-image/`,
            data
        )
        return response.data;
    }

    public static async getVideoByProperty(id: string | number) {
        const response = await this.enviarRequestAsync<any>(
            'GET',
            `/get-videos-byProperty/${id}/`
        )
        return response.data;
    }

    public static async uploadVideo(data: any) {
        const response = await this.enviarRequestAsync<any>(
            'POST',
            `/create-video/`,
            data
        )
        return response.data;
    }

    public static async deleteProperty(id: string) {
        const response = await this.enviarRequestAsync<any>(
            'DELETE',
            `/delete-property/${id}/`,
        )
        return response.data;
    }

    public static async deleteImage(id: string) {
        const response = await this.enviarRequestAsync<any>(
            'DELETE',
            `/delete-image/${id}/`,
        )
        return response.data;
    }
    public static async deleteVideo(id: string) {
        const response = await this.enviarRequestAsync<any>(
            'DELETE',
            `/delete-video/${id}/`,
        )
        return response.data;
    }

    public static async checkPassword(data:any) {
        const response = await this.enviarRequestAsync<any>(
            'POST',
            `/check-password/`,
            data
        )
        return response.data;
    }

    public static async getLogs(id: string | number, page: string | number) {
        const response = await this.enviarRequestAsync<any>(
            'GET',
            `/get-logs/${id}/${page}/`
        )
        return response.data;
    }
}