import React, { useEffect, useState } from 'react'
import styles from './index.module.css'
import UploadField from '../uploadField'
import DateInput from '../dateInput'
import RoundedButton from '../roundedButton'
import RoundedInput from '../roundedInput'
import ApiService from '../../ApiService'
import { PropertyResponse } from '../../models'
import { useForm } from 'react-hook-form'
import LoadingButton from '../loadingButton'
import FeedbackComponent from '../feedbackComponent'
import RoundedSelect from '../roundedSelect'

interface Props {
    type: 'image' | 'video' | undefined
    closeCallback: () => void
    property: PropertyResponse
}

interface FormData {
  arquivo: string | FileList | null;
  data: string;
  descricao: string;
}

export default function AddImageModal(props: Props) {
  const { handleSubmit, setValue, watch } = useForm<FormData>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [feedback, setFeedback] = useState<{type: 'success' | 'error', message: string} | undefined>()

  const descriptionOptions = [
    'Entrada',
    'Saída'
  ]

  const handleSendData = async (data: FormData) => {
    setIsLoading(true)
    try{
      const form = new FormData()
      form.append('image',data.arquivo?.[0] ?? '') 
      form.append('fileName',`${props.property?.id}_${Date.now()}`)
      form.append('property', props.property?.id?.toString())
      form.append('description', (data.descricao && data.descricao !== 'Descrição'? data.descricao : '') ?? '')
      form.append('date', data.data)
      if(props.type === 'image') {
        const uploadImage = await ApiService.uploadImage(form)
      }
      if(props.type === 'video') {
        const uploadVideo = await ApiService.uploadVideo(form)
      }
      setFeedback({type:'success', message:'Upload feito com sucesso!'})
    } catch (e: any) {
        console.log(e)
        setFeedback({type:'error', message:'Ocorreu um erro ao fazer o upload'})
    }
    finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setValue('data', new Date().toISOString().split('T')[0])
  },[])

  useEffect(() => {
    setTimeout(() => {
        setFeedback(undefined)
    },5000)
  },[feedback])

  return (
    <div className={styles.background}>
        <div className={styles.backButton}  onClick={props.closeCallback}>
          <RoundedButton buttonText='Voltar' buttonType='button' />
        </div>
        <p className={styles.title}>{props.type === 'image'? 'Adicionar foto' : 'Adicionar vídeo'}</p>
        <form onSubmit={handleSubmit(handleSendData)} className={styles.form}>
          <div className={styles.inputRow}>
            <p>Arquivo</p>
            <div className={styles.fileInput}>
              <UploadField label='Escolher arquivo' onChangeCallback={(file) => setValue('arquivo',file)} />
            </div>
          </div>
          <div className={styles.inputRow}>
            <p>{props.type === 'image' ? 'Data da foto' : 'Data do vídeo'}</p>
            <div className={styles.largeInput}>
              <DateInput default={watch('data')} onChangeCallback={(date: string) => setValue('data',date)}/>
            </div>
          </div>
          <div className={styles.inputRow}>
            <p>Descrição</p>
            <div className={styles.largeInput}>
              <RoundedSelect label='Descrição' items={descriptionOptions} value={watch('descricao')} onChange={(value: string) => setValue('descricao',value)}/>
            </div>
          </div>
          {feedback? 
            <div className={styles.feedback}>
              <FeedbackComponent type={feedback.type} message={feedback.message} />
            </div>
          :
            <div className={styles.sendButton}>
              {isLoading?
                <LoadingButton />
              :
                <RoundedButton buttonText='Salvar' buttonType='submit' />
              }
            </div>
          }
        </form>
    </div>
  )
}
