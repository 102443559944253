import React, { useEffect, useState } from 'react'
import styles from './index.module.css'
import Header from '../components/header'
import RoundedButton from '../components/roundedButton'
import { Delete, Info, Search } from '@mui/icons-material'
import RoundedInput from '../components/roundedInput'
import { Checkbox, Modal } from '@mui/material'
import NewUserModal from '../components/newUserModal'
import ApiService from '../ApiService'
import { UserResponse } from '../models'
import EditIcon from '@mui/icons-material/Edit';
import Loading from '../components/loading'

export default function UsersPage() {
  const [newUserModal, setNewUserModal] = useState<boolean>(false)
  const [users, setUsers] = useState<UserResponse[]>()
  const [selectedUser, setSelectedUser] = useState<UserResponse>()
  const [userData, setUserData] = useState<UserResponse>()
  const [page, setPage] = useState<number>(1)
  const [lastFetched, setLastFetched] = useState<number>()
  const [fetching, setFetching] = useState<boolean>(false)
  const [searchTerm, setSearchTerm] = useState<string>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const tableColumns = [
        'Nome',
        'Sobrenome',
        'E-mail',
        'Telefone',
        'Perfil',
        'Ações'
  ]

  const getUserList = async (page:number) => {
    setIsLoading(true)
    try{
      let actualData = users
      const data:UserResponse[] = await ApiService.getUserById('limit100',page) as UserResponse[]
      if(actualData && page !== 1) {
        actualData?.push(...data)
      }
      else {
        actualData = data
      }
      setUsers(actualData)
      setLastFetched(data.length)
    }
    catch (error: any) {
      console.log(error)
    }
    finally {
      setIsLoading(false)
    }
  }

  const handleScroll = async (e:any) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    const bufferHeight = 50;
    // Check if the user has scrolled to the bottom
    if (
        Math.floor(scrollTop + clientHeight) >= Math.floor(scrollHeight/2) - bufferHeight && 
        lastFetched && lastFetched >= 100 && 
        !fetching &&
        searchTerm !== ''
      ) {
      setFetching(true)
      await getUserList(page+1);
      setPage(page => page+1)
      setFetching(false)
    }
  };

  const handleSearch = async (term: string) => {
    const trimmedSearchTerm = term.trim();
    const encodedSearchTerm = encodeURIComponent(trimmedSearchTerm);
    setSearchTerm(trimmedSearchTerm)
  
    try {
      if (term && term?.length >= 4) {
        const response = await ApiService.getUserBySearchTerm(encodedSearchTerm)
        const users = response.data as UserResponse[]
        setUsers(users);
        return
      }
      if (!term || term === '') {
        getUserList(1)
      }
    } catch (error:any) {
      if (error?.name === 'AbortError') {
        // Ignore the request cancellation error
      } else {
        console.error('Error searching users:', error);
      }
    }
  }

  
  useEffect(() => {
    getUserList(page)
    const user = JSON.parse(localStorage.getItem('userData') ?? '')
    if(user) {
      setUserData(user)
    }
  },[])

  return (
    <div className={styles.background}>
        <Modal open={newUserModal} onClose={() => setNewUserModal(false)}>
          <NewUserModal closeCallback={() => setNewUserModal(false)} userToEdit={selectedUser}/>
        </Modal>
        <Header />
        <p className={styles.pageTitle}>Lista de Usuários</p>
        <div className={styles.menuRow}>
          <div className={styles.newPropertyButton}>
            <RoundedButton buttonText='Novo Usuário' buttonType='button' onClickCallback={() => {setSelectedUser(undefined);setNewUserModal(true)}}/>
          </div>
          <div className={styles.searchPropertyInput}>
            <Search />
            <RoundedInput disableLabel label='Pesquisar Usuário' type='text' onChangeCallback={handleSearch} />
          </div>
        </div>
        <div>
          <table id='table' className={styles.table}>
            <thead className={styles.tableHead}>
              <tr className={styles.tr}>
                {tableColumns.map((column) => 
                  <th className={styles.th}>
                      {column}
                  </th>
                )}
              </tr>
            </thead>
            <tbody className={styles.tableBody} onScroll={handleScroll}>
            {isLoading && <Loading/>}
            {users?.map((user) =>
              <tr className={styles.tr}>
                  <td className={styles.td}>
                    {user?.firstName}
                  </td>
                  <td className={styles.td}>
                    {user?.lastName}
                  </td>
                  <td className={styles.td}>
                    {user?.email}
                  </td>
                  <td className={styles.td}>
                    {Number(user?.phone) !== 0? user?.phone : ''}
                  </td>
                  <td className={styles.td}>
                    {user?.account_type}
                  </td>
                  {(userData?.account_type === 'admin' || userData?.account_type === 'IMOBILIARIA') && <>
                      <td className={styles.td}>
                        <div className={styles.iconsContainer}>
                          <div className={styles.blackIcons} onClick={() => {setSelectedUser(user); setNewUserModal(true)}}>
                            <EditIcon />
                          </div>
                        </div>
                      </td>
                  </>}
              </tr>
            )}
            </tbody>
          </table>
        </div>
    </div>
  )
}
