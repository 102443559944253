import React, { useEffect, useState } from 'react'
import styles from './index.module.css'
import { PropertyResponse, UserResponse } from '../models'
import { useLocation } from 'react-router-dom'
import Header from '../components/header'
import RoundedInput from '../components/roundedInput'
import UploadField from '../components/uploadField'
import RoundedSelect from '../components/roundedSelect'
import RoundedButton from '../components/roundedButton'
import { Modal } from '@mui/material'
import AddImageModal from '../components/addImageModal'
import ApiService from '../ApiService'
import LoadingButton from '../components/loadingButton'
import Loading from '../components/loading'
import { Delete } from '@mui/icons-material'
import DeleteModal from '../components/deleteModal'
import { parseDate } from '../utils/parseDate'


export default function Imovel() {
    const [openAddImageModal, setOpenAddImageModal] = useState<'image' | 'video' | undefined>()
    const [images, setImages] = useState<any>()
    const [videos, setVideos] = useState<any>()
    const [userData, setUserData] = useState<UserResponse>()
    const [isLoadingImages, setIsLoadingImages] = useState<boolean>(false)
    const [isLoadingVideos, setIsLoadingVideos] = useState<boolean>(false)
    const [openDeleteModal, setOpenDeleteModal] = useState<any>()
    
    const data:{
        property: PropertyResponse, 
        tenantList: UserResponse[], 
        landlordList: UserResponse[]
    } = useLocation().state

    const getUserFullName = (user: UserResponse | undefined) => {
        return user ? `${user.firstName} ${user.lastName}` : '';
    }

    const handleGetPropertyImages = async () => {
        setIsLoadingImages(true)
        try {
            const response = await ApiService.getImageByProperty(data?.property?.id)
            setImages(response)
        }
        catch(e: any) {
            console.log(e)
        }
        finally {
            setIsLoadingImages(false)
        }
    }

    const handleGetPropertyVideos = async () => {
        setIsLoadingVideos(true)
        try {
            const response = await ApiService.getVideoByProperty(data?.property?.id)
            setVideos(response)
        }
        catch(e: any) {
            console.log(e)
        }
        finally {
            setIsLoadingVideos(false)
        }
    }

    const handleDeleteMedia = async (media: {type: string, id: string }) => {
        try {
            if(media.type === 'image') {
                await ApiService.deleteImage(media.id)
            }
            if(media.type === 'video') {
                await ApiService.deleteVideo(media.id)
            }
        }
        catch (error: any) {
            console.log(error)
        }
    }

    const getUserData = async () => {
        const data = await JSON.parse(localStorage.getItem('userData') ?? '')
        setUserData(data)
    }

    useEffect(() => {
        handleGetPropertyImages()
        handleGetPropertyVideos()
        getUserData()
    },[openAddImageModal, openDeleteModal])

  return (
    <div className={styles.background}>
        <Modal open={openAddImageModal? true : false} onClose={() => setOpenAddImageModal(undefined)}>
            <AddImageModal type={openAddImageModal} property={data.property} closeCallback={() => setOpenAddImageModal(undefined)}/>
        </Modal>
        <Modal open={openDeleteModal? true : false} onClose={() => setOpenDeleteModal(undefined)}>
            <DeleteModal resourceToDelete={openDeleteModal?.id} onCloseCallback={() => setOpenDeleteModal(undefined)} onAcceptCallback={() => handleDeleteMedia(openDeleteModal)}/>
        </Modal>
        <Header />
        <p className={styles.pageTitle}>{`Imóvel ${data.property?.code}`}</p>
        {(userData?.account_type === 'admin' || userData?.account_type === 'IMOBILIARIA') && <>
            <div className={styles.inputRow}>
                <div className={styles.mediumInput}>
                    <RoundedInput label='Código do imóvel' type='number' value={data.property?.code?.toString()} onChangeCallback={(value:string) => {}} disabled/>
                </div>
                <div className={styles.mediumInput}>
                    <RoundedInput label='Proprietário' type='text' value={getUserFullName(data.landlordList?.find(landlord => landlord.id === data.property.landlord))} onChangeCallback={(value:string) => {}} disabled/>
                </div>
                <div className={styles.mediumInput}>
                    <RoundedInput label='Inquilino' type='text' value={getUserFullName(data.tenantList?.find(tenant => tenant.id === data.property.tenant))} onChangeCallback={(value:string) => {}} disabled/>
                </div>
            </div>
        </>}
        <div className={styles.inputRow}>
            <div className={styles.largeInput}>
                <RoundedInput label='Endereço' type='text' value={data.property?.address?.toString()} onChangeCallback={(value:string) => {}} disabled/>
            </div>
            <div className={styles.smallInput}>
                <RoundedInput label='Número' type='text' value={data.property?.address_no?.toString()} onChangeCallback={(value:string) => {}} disabled/>
            </div>
            <div className={styles.mediumInput}>
                <RoundedInput label='Complemento' type='text' value={data.property?.address_l2?.toString()} onChangeCallback={(value:string) => {}} disabled/>
            </div>
            <div className={styles.mediumInput}>
                <RoundedInput label='CEP' type='text' value={data.property?.cep?.toString()} onChangeCallback={(value:string) => {}} disabled/>
            </div>
        </div>
        <div className={styles.inputRow}>
            
            <div className={styles.mediumInput}>
                <RoundedInput label='Bairro' type='text' value={data.property?.address_neighborhood?.toString()} onChangeCallback={(value:string) => {}} disabled/>
            </div>
            <div className={styles.mediumInput}>
                <RoundedInput label='Cidade' type='text' value={data.property?.city?.toString()} onChangeCallback={(value:string) => {}} disabled/>
            </div>
            <div className={styles.smallInput}>
                <RoundedInput label='Estado' type='text' value={data.property?.state?.toString()} onChangeCallback={(value:string) => {}} disabled/>
            </div>
            <div className={styles.mediumInput}>
                <RoundedInput label='País' type='text' value={data.property?.country?.toString()} onChangeCallback={(value:string) => {}} disabled/>
            </div>
        </div>
        <div className={styles.inputRow}>
            <div className={styles.tallInput}>
                <RoundedInput label='Observações' type='text' value={data.property?.details?.toString()} onChangeCallback={(value:string) => {}} disabled/>
            </div>
        </div>
        {(userData?.account_type === 'admin' || userData?.account_type === 'IMOBILIARIA') && <>
            <div className={styles.buttonsRow}>
                <div className={styles.uploadImageButton} onClick={() => setOpenAddImageModal('image')}>
                    <RoundedButton buttonText='Adicionar Foto' buttonType='button' />
                </div>
                <div className={styles.uploadVideoButton} onClick={() => setOpenAddImageModal('video')}>
                    <RoundedButton buttonText='Adicionar Vídeo' buttonType='button' />
                </div>
            </div>
        </>}
        <p className={styles.pageTitle}>Fotos</p>
        {isLoadingImages ?
            <Loading />
        :
            <div className={styles.grid}>
                {images?.map((image: any) =>
                    <div className={styles.imageContainer}>
                        <img className={styles.image} src={image.image} />
                        <div className={styles.imageDescription}>
                            <p>{`Descrição: ${image.description? image.description : ''}`}</p>
                            <p>{`Data da foto: ${image.date_taken? image.date_taken: ''}`}</p>
                            <p>{`Adicionado em: ${parseDate(image.date_added)}`}</p>
                        </div>
                        {(userData?.account_type === 'admin' || userData?.account_type === 'IMOBILIARIA') &&
                            <div className={styles.redIcons} onClick={() => setOpenDeleteModal({'type': 'image',...image})}>
                                <Delete />
                            </div>
                        }
                    </div>
                )}
            </div>
        }
        <p className={styles.pageTitle}>Vídeos</p>
        {isLoadingVideos?
            <Loading />
        :
            <div className={styles.grid}>
                {videos?.map((image: any) =>
                    <div className={styles.imageContainer}>
                        <iframe className={styles.image} frameBorder={0} src={` https://drive.google.com/file/d/${image?.link}/preview`} allow="autoplay"/>
                        <div className={styles.imageDescription}>
                            <p>{`Descrição: ${image.description? image.description : ''}`}</p>
                            <p>{`Data do vídeo: ${image.date_taken? image.date_taken: ''}`}</p>
                            <p>{`Adicionado em: ${parseDate(image.date_added)}`}</p>
                        </div>
                        {(userData?.account_type === 'admin' || userData?.account_type === 'IMOBILIARIA') &&
                            <div className={styles.redIcons} onClick={() => setOpenDeleteModal({'type': 'video',...image})}>
                                <Delete />
                            </div>
                        }
                    </div>
                )}
            </div>
        }
    </div>
  )
}
